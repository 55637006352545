import { DownloadClientInstance } from '@/infrastructure/download-client/dl-client'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'dl-requeue-button',
  props: {
    orderId: String
  },
  setup (props) {
    function requeue () {
      event?.preventDefault()
      DownloadClientInstance.orders_RequeueOrder(props.orderId as string).then((response) => {
        location.reload()
      })
    }
    return { requeue }
  }
})
