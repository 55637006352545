import { LogLevel } from '../download-client/download-client'

export const DefaultDatasetPageSize = parseInt(
  process.env.VUE_APP_DEFAULT_DATASET_PAGESIZE || '10'
)
export const DefaultApiPort = parseInt(process.env.VUE_APP_API_PORT || '7119')
export const DefaultBaseUri = process.env.VUE_APP_BASE_URI || 'localhost'
export const DefaultApiVersionPath = process.env.VUE_APP_API_VERSION_PATH || ''
export const IsProduction = process.env.VUE_APP_ENV === 'production'
export const ApiLogLevel =
  (process.env.VUE_APP_API_LOG_LEVEL as LogLevel) || LogLevel.Error
export const DefaultLoginUrl = `${DefaultBaseUri}:${DefaultApiPort}${DefaultApiVersionPath}/auth/login`
export const DefaultLogoutUrl = `${DefaultBaseUri}:${DefaultApiPort}${DefaultApiVersionPath}/auth/logout`
export const Environment = (process.env.VUE_APP_ENV || process.env.NODE_ENV || ASPNETCORE_ENVIRONMENT || 'production').toLowerCase()
export const LocalBaseUri = 'http://localhost:44441'
