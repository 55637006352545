const DatasetModuleEdit = () => import(/* webpackChunkName: "dataset" */ '@/modules/product-module/modules/dataset-module/dataset-module.vue')

export const RouteDatasetEdit = {
  path: '/dataset/:id',
  name: 'dataset',
  component: DatasetModuleEdit,
  meta: {
    breadcrumb: {
      label: 'dataset'
    },
    authorize: false
  }
}

export const DatasetRoutes = [RouteDatasetEdit]

export default DatasetRoutes
