const DownloadsDetailModule = () => import('@/modules/downloads-module/modules/download-detail-module/download-detail-module.vue')

export const RouteDownloadDetail = {
  path: '/downloads/:id',
  name: 'downloadsdetail',
  component: DownloadsDetailModule,
  meta: {
    breadcrumb: {
      label: 'Download Detail'
    },
    authorize: []
  }
}

const DownloadDetailRoutes = [RouteDownloadDetail]
export default DownloadDetailRoutes
