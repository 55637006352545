import VueCompositionAPI from '@vue/composition-api'
import { VueConstructor } from 'vue'
import WebComponents from '@/infrastructure/web-components'
import DlComponents from '@/infrastructure/dl-components'
import IpproComponents from '@/infrastructure/ippro-components'
import { PiniaVuePlugin } from 'pinia'
import jsonViewer from '../json-viewer'

export const loadVueLibs = (vueInstance: VueConstructor<Vue>) => {
  vueInstance.use(VueCompositionAPI)
  vueInstance.use(WebComponents)
  vueInstance.use(DlComponents)
  vueInstance.use(IpproComponents)
  vueInstance.use(PiniaVuePlugin)
  vueInstance.use(jsonViewer)

  return vueInstance
}
