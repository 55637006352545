import { EnumAlertMethod, EnumAlertType, useAlertStore } from '../store/alert'
import { ErrorToAlert } from './error-parser'

import { v4 as uuidv4 } from 'uuid'

export const GenerateErrorToaster = (err: unknown) => {
  useAlertStore().addAlert(ErrorToAlert(err))
}

export const GenerateSuccessToaster = (title:string, message: string) => {
  useAlertStore().addAlert({
    id: uuidv4(),
    closable: true,
    method: EnumAlertMethod.Toast,
    type: EnumAlertType.Success,
    title,
    message
  }, 5000)
}
