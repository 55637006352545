import { computed, defineComponent } from '@vue/composition-api'

export interface IDlPeriodDate {
  start: Date[]
  end: Date[]
}

export default defineComponent({
  name: 'dl-period',
  props: ['value'],
  emits: ['date-change'],
  setup (props, { emit }) {
    const date = computed({
      get: (): IDlPeriodDate => {
        return props.value
      },
      set: (value: IDlPeriodDate) => {
        emit('input', value)
      }
    })

    const maxDate = computed(() =>
      date.value.end.length >= 1 ? date.value.end[0].toISOString() : null
    )
    const minDate = computed(() =>
      date.value.start.length >= 1 ? date.value.start[0].toISOString() : null
    )

    return { date, maxDate, minDate }
  }
})
