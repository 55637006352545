import { IRouteConfig } from '@/infrastructure/router/interfaces'

const UserComponent = () => import('../user-module.vue')

export const RouteUser:IRouteConfig = {
  path: '/gebruiker',
  name: 'Gebruiker',
  component: UserComponent,
  meta: {
    title: 'Gebruikers gegevens'
  }
}

export const UserRoutes = [RouteUser]

export default UserRoutes
