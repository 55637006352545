const ProductenComponent = () => import(/* webpackChunkName: "products" */ '@/modules/product-module/product-module.vue')

export const RouteProduct = {
  path: '/producten',
  name: 'producten',
  component: ProductenComponent,
  meta: {
    breadcrumb: {
      label: 'Producten'
    },
    authorize: []
  }
}

const ProductRoutes = [RouteProduct]

export default ProductRoutes
