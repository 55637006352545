import { DownloadClientInstance } from '@/infrastructure/download-client/dl-client'
import {
  AdminPredefinedConfigurationRequest,
  EnumFormatPredefined,
  EnumVersnijdingsType,
  FormatCode,
  GetConfigurationQueryResponse,
  LogLevel,
  ProductConfiguratieFile,
  ProductConfiguratieValidationError,
  RequestForFormatenHydraItem,
  RequestForProductDetailsHydraItem
} from '@/infrastructure/download-client/download-client'
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  Ref,
  ref
} from '@vue/composition-api'
import { DlLogger } from '@/infrastructure/dl-logger'
import axios, { Canceler } from 'axios'
import { HandleError } from '@/infrastructure/alert-handler/error-parser'
import { GenerateErrorToaster } from '@/infrastructure/alert-handler/alert-toaster'
import { useFormatenComponentStore } from '../dl-formaten/dl-formaten-state'

export interface IGenerateOptions {
  geenVersnijding: boolean
  provincie: boolean
  dailyGenerate: boolean
}

export class GenerateSaveOptions {
  dailyGenerate: boolean
  generateVersnijdingen: number[]
}

export default defineComponent({
  name: 'dl-predefined-documents',
  props: {
    product: Object as PropType<RequestForProductDetailsHydraItem>,
    allowVersnijding: Boolean,
    isAdmin: Boolean,
    formaten: Array as PropType<FormatCode[]>
  },
  setup (props, { emit }) {
    const formatenTaxonomyStore = useFormatenComponentStore()
    const state: { formatenTaxonomies: RequestForFormatenHydraItem[] } =
      reactive({
        formatenTaxonomies: []
      })
    const productDetail = computed(
      () => props.product as RequestForProductDetailsHydraItem
    )
    const key = ref(0)
    enum VersnijdingsType {
      GeenVersnijding = 1,
      Provincie = 6
    }
    const canGenerate: Ref<boolean> = computed(
      () =>
        !generating.value &&
        props.isAdmin &&
        props.product !== undefined &&
        props.product.formaten !== undefined &&
        props.product.formaten.length > 0
    )
    const canGenerateProvincie: Ref<boolean> = computed(
      () =>
        props.product !== undefined &&
        props.product.versnijdingen !== undefined &&
        props.product.versnijdingen.some(
          (v) => v.id === VersnijdingsType.Provincie
        )
    )
    const canGenerateGeenVersnijding: Ref<boolean> = computed(
      () =>
        props.product !== undefined &&
        props.product.versnijdingen !== undefined &&
        props.product.versnijdingen.some(
          (v) => v.id === VersnijdingsType.GeenVersnijding
        )
    )
    const generateOptions: Ref<IGenerateOptions> = ref({
      geenVersnijding: false,
      provincie: false,
      dailyGenerate: false
    })
    const hasGenerateOptionSelected = computed(() => generateOptions.value.geenVersnijding || generateOptions.value.provincie)
    const isGenerateOpen = ref(false)
    const isCheckOpen = ref(false)
    const generating = ref(false)
    const loading = ref(false)
    const hasError = ref(false)
    const errorFileCount = ref(0)
    const scanResult: Ref<GetConfigurationQueryResponse | undefined> =
      ref(undefined)
    const files = computed(() => {
      if (scanResult.value) {
        return scanResult.value.fileList as ProductConfiguratieFile[]
      }
      return []
    })
    const scannedFormaten = computed(() => {
      const output: EnumFormatPredefined[] = []
      if (scanResult.value && scanResult.value.fileList) {
        scanResult.value.fileList.forEach((x) => {
          const formaat = x.formaat ? (x.formaat as EnumFormatPredefined) : null
          if (formaat && !output.includes(formaat)) {
            output.push(formaat)
          }
        })
      }
      return output.length > 0 ? ' ' + output.map((z) => z).join(', ') : ''
    })

    const versnijdingen = computed(() => {
      const output: Array<EnumVersnijdingsType | undefined> = []
      if (scanResult.value && scanResult.value.fileList) {
        scanResult.value.fileList.forEach((x) => {
          if (!output.includes(x.versnijdingsType)) {
            output.push(x.versnijdingsType)
          }
        })
      }
      return output
        .map((type) => {
          switch (type) {
            case EnumVersnijdingsType.GeenVersnijding:
              return 'Geen versnijding'
            case EnumVersnijdingsType.Gemeente:
              return 'Gemeente'
            case EnumVersnijdingsType.Provincie:
              return 'Provincie'
            case EnumVersnijdingsType.KaartBlad:
              return 'Kaartblad'
            default:
              return ''
          }
        })
        .filter((x) => x !== '')
    })

    const debugOptions: Ref<any|null> = ref({
      defaultOpen: false, // default extend
      rootKeyName: 'Validation errors', // root key name
      sortable: false, // sortable for key
      defaultOpenDepth: 1, // default extend max depth
      styles: { // Text color
        key: { // key text color
          string: '#555',
          number: '#881391'
        },
        value: { // value text color
          string: '#c41a16',
          number: ' #1c00cf',
          boolean: '#0d22aa',
          null: '#e08331',
          undefined: '#e08331',
          function: '#067bca'
        }
      },
      parseLink: true, // Parsing text link
      keyNameQuote: false, // The key name quote  value: false / single / double
      valueNameQuote: 'double', //  single / double
      hints: { // Prompt when folding word
        array: ['item', 'items'],
        object: ['property', 'properties']
      }
    })

    const errors = computed(() => {
      if (scanResult.value) {
        return [
          ...new Set(
            joinErrorCodes(
              scanResult.value
                ?.validationErrors as ProductConfiguratieValidationError[]
            ).map((error) =>
              mapCodeToError(
                error.errorMessage as string,
                error.missingValues
                  ? error.missingValues.length
                  : error.bestandsNaam
                    ? 1
                    : undefined,
                error.missingValues?.join(', '),
                error.fileCount,
                error.bestandsNaam
              )
            )
          )
        ].join('')
      }
      return []
    })
    let cancel: Canceler | null = null

    function mapCodeToError (
      error: string,
      aantalMissingValues?: number,
      codesString?: string,
      fileCount?: number,
      fileName?: string
    ): string {
      errorFileCount.value += aantalMissingValues ?? 0
      const liStart = '<li class="validation-error-list-item">'
      const liChildStart = '<li>'
      const liEnd = '</li>'
      const ulStart =
        '<ul class="validation-error-list-child validation-error-list">'
      const ulEnd = '</ul>'
      switch (error) {
        case 'FileCannotDetermineVersnijdingsType':
          return `${liStart}Het versnijdingstype kon niet bepaald worden aan de hand van de bestandsnaam.${liEnd}`
        case 'FilesProvincieCountInvalid':
          return `${liStart}Het aantal provincies moet uit 5 bestaan.${liEnd}`
        case 'FilesKaartbladNot42Or43':
          return `${liStart}Het aantal NGI 1/1 kaartbladen moet uit 42 of 43 bestaan. Gedetecteerd: ${
            fileCount || 42 - (aantalMissingValues as number)
          }.${liEnd}`
        case 'FilesKaartbladNotNumberFrom1To42Or1To43NotCorrect':
          return `${liStart}De nummering van NGI 1/1 kaartbladen is niet correct.${ulStart}${liChildStart}${
            codesString !== undefined
              ? (('Deze kaartbladen ontbreken: ' + codesString) as string)
              : ''
          }.${liEnd}${ulEnd}${liEnd}`
        case 'FilesGemeenteCountInvalid':
          return aantalMissingValues
            ? `${liStart}Het aantal gemeenten moet 300 zijn. Gedetecteerd: ${
                fileCount || 300 - (aantalMissingValues as number)
              }.${ulStart}${liChildStart}${
                codesString !== undefined
                  ? (('De volgende NIS-codes ontbreken: ' +
                      codesString) as string)
                  : ''
              }.${liEnd}${ulEnd}${liEnd}`
            : `${liStart}Het aantal gemeenten moet 300 zijn. Gedetecteerd: ${
                fileCount || 300 - (aantalMissingValues as number)
              }.${liEnd}`
        case 'FilesGemeenteNotNisCode':
          return `${liStart}Er zijn NIS-codes van gemeenten gedetecteerd die niet herkend zijn${
            codesString !== undefined
              ? ((': ' + codesString) as string)
              : fileName !== undefined
              ? ': ' + fileName
              : ''
          }.${liEnd}`
        case 'MultipleFormatenDetectedVersnijdingenNotTheSame':
          return `${liStart}Er zijn verschillende bestandsformaten gedetecteerd met andere type versnijdingen. De type versnijdingen moeten hetzelfde zijn voor alle bestandsformaten.${liEnd}`
        case 'NoFilesFound':
          return 'Geen bestanden op deze locatie'
        case 'FileGeenVersnijdingSameFormat':
          return 'Er kan slechts 1 onversneden bestand bestaan per formaat.'
        default:
          return ''
      }
    }

    function joinErrorCodes (
      errorCodes: ProductConfiguratieValidationError[]
    ): ProductConfiguratieValidationError[] {
      const out: ProductConfiguratieValidationError[] = []
      errorCodes.forEach((errorCodes) => {
        const index = out.findIndex(
          (y) => y.errorMessage === errorCodes.errorMessage
        )
        if (index >= 0) {
          if (errorCodes.missingValues !== null) {
            if (out[index].missingValues !== null) {
              out[index].missingValues!.push(...errorCodes.missingValues!)
            } else if (out[index].missingValues === undefined) {
              out[index].missingValues = errorCodes.missingValues
            }
          } else if (errorCodes.bestandsNaam !== null) {
            if (out[index].missingValues !== null) {
              out[index].missingValues!.push(errorCodes.bestandsNaam!)
            } else if (out[index].missingValues === null) {
              out[index].missingValues = [errorCodes.bestandsNaam!]
            }
          }
        } else {
          if (errorCodes.missingValues === null && errorCodes.bestandsNaam !== undefined) {
            errorCodes.missingValues = [errorCodes.bestandsNaam]
          }
          out.push(errorCodes)
        }
      })
      return out
    }

    function openModal () {
      isGenerateOpen.value = true
      key.value += 1
    }

    async function checkForFiles () {
      generating.value = true
      isGenerateOpen.value = false
      if (
        props.product &&
        props.product.configuratie &&
        props.product.configuratie.fileList &&
        props.product.configuratie.fileList.length > 0
      ) {
        isCheckOpen.value = true
      } else {
        generatePredefinedFiles()
      }
    }

    async function generatePredefinedFiles () {
      scanResult.value = undefined
      isGenerateOpen.value = false
      isCheckOpen.value = false
      key.value += 1
      try {
        const selectiesChoice: number[] = []
        if (generateOptions.value.geenVersnijding) {
          selectiesChoice.push(VersnijdingsType.GeenVersnijding)
        }
        if (generateOptions.value.provincie) {
          selectiesChoice.push(VersnijdingsType.Provincie)
        }
        const configuration: AdminPredefinedConfigurationRequest = {
          productNumber: props.product?.productNummer as number,
          formaten: mapFormaten(),
          selecties: selectiesChoice
        }
        DownloadClientInstance.orders_CreatePredefinedFiles(
          configuration,
          new axios.CancelToken((canceler) => {
            cancel = canceler
          })
        ).then(() => {
          emit('save-generate', {
            dailyGenerate: generateOptions.value.dailyGenerate,
            generateVersnijdingen: selectiesChoice
          } as GenerateSaveOptions)
        })
      } catch (err) {
        if (HandleError(err)) {
          hasError.value = true
          GenerateErrorToaster(err)
          DlLogger(
            LogLevel.Error,
            'dl-predefined-documents.ts',
            'scanPredefinedDocuments',
            err
          )
        }
      }
    }

    const mapFormaten = (): string[] => {
      return state.formatenTaxonomies
        .filter((x) =>
          props.formaten?.map((x) => x.id)?.includes(x.formaat?.id as number)
        )
        .map((y) => y.formaat?.formaat as string) as string[]
    }

    const cancelGenerate = () => {
      isGenerateOpen.value = false
      isCheckOpen.value = false
      generating.value = false
    }

    async function scanPredefinedDocuments () {
      if (cancel) {
        cancel()
      }
      loading.value = true
      hasError.value = false
      scanResult.value = undefined
      errorFileCount.value = 0
      try {
        const response =
          await DownloadClientInstance.products_GetProductConfiguration(
            productDetail.value.productNummer,
            new axios.CancelToken((canceler) => {
              cancel = canceler
            })
          )
        scanResult.value = response
      } catch (err) {
        if (HandleError(err)) {
          hasError.value = true
          GenerateErrorToaster(err)
          DlLogger(
            LogLevel.Error,
            'dl-predefined-documents.ts',
            'scanPredefinedDocuments',
            err
          )
        }
      }
      loading.value = false
    }

    onMounted(() => {
      formatenTaxonomyStore.getFormaten().then((formaten) => {
        state.formatenTaxonomies = formaten as RequestForFormatenHydraItem[]
      })

      generateOptions.value.dailyGenerate =
        props.product?.autoGenerateOrder ?? false

      if (
        props.product?.autoGeneratedVersnijdingen?.find(
          (v) => v.id === VersnijdingsType.GeenVersnijding
        )
      ) {
        generateOptions.value.geenVersnijding = true
      }

      if (
        props.product?.autoGeneratedVersnijdingen?.find(
          (v) => v.id === VersnijdingsType.Provincie
        )
      ) {
        generateOptions.value.provincie = true
      }
    })

    return {
      productDetail,
      scanPredefinedDocuments,
      loading,
      scanResult,
      files,
      scannedFormaten,
      versnijdingen,
      errors,
      hasError,
      isGenerateOpen,
      generating,
      canGenerate,
      canGenerateGeenVersnijding,
      canGenerateProvincie,
      generateOptions,
      generatePredefinedFiles,
      openModal,
      key,
      checkForFiles,
      cancelGenerate,
      isCheckOpen,
      errorFileCount,
      debugOptions,
      hasGenerateOptionSelected
    }
  }
})
