const DownloadsModule = () => import('@/modules/downloads-module/downloads-module.vue')

export const RouteDownloads = {
  path: '/downloads',
  name: 'downloads',
  component: DownloadsModule,
  meta: {
    breadcrumb: {
      label: 'Downloads'
    },
    authorize: []
  }
}

const DownloadRoutes = [RouteDownloads]

export default DownloadRoutes
