import { DownloadClientInstance } from '@/infrastructure/download-client/dl-client'
import { RequestForFormatenHydraItem } from '@/infrastructure/download-client/download-client'
import { defineStore } from 'pinia'

export const useFormatenComponentStore = defineStore('formaten-component', {
  state: ():{formatenTaxonomies:RequestForFormatenHydraItem[]| null} => ({
    formatenTaxonomies: null
  }),
  actions: {
    async getFormaten () {
      return (
        this.formatenTaxonomies ||
                DownloadClientInstance.taxonomy_Formaten().then((formaten) => {
                  this.formatenTaxonomies = formaten
                  return formaten
                })
      )
    }
  }
})
