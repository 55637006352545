const EntityModuleEdit = () => import(/* webpackChunkName: "entity" */ '@/modules/product-module/modules/entity-module/entity-module.vue')

export const RouteEntityEdit = {
  path: '/entity/:id',
  name: 'entity',
  component: EntityModuleEdit,
  meta: {
    breadcrumb: {
      label: 'entity'
    },
    authorize: false
  }
}

export const EntityEditRoutes = [RouteEntityEdit]

export default EntityEditRoutes
