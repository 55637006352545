import { IRouteConfig } from '@/infrastructure/router/interfaces'

const ErrorComponent = () => import('../error-module.vue')

export const RouteError:IRouteConfig = {
  path: '/error',
  name: 'error',
  component: ErrorComponent,
  meta: {
    title: 'Fout'
  }
}

export const ErrorRoutes = [RouteError]

export default ErrorRoutes
