import { FormatCode, RequestForFormatenHydraItem } from '@/infrastructure/download-client/download-client'
import { computed, defineComponent, onMounted, PropType, reactive, ref, Ref } from '@vue/composition-api'
import { useFormatenComponentStore } from './dl-formaten-state'
import { useUserStore } from '@/infrastructure/store/user'

export default defineComponent({
  name: 'dl-formaten',
  props: {
    value: {
      type: Array as PropType<FormatCode[]>,
      required: true
    }
  },
  setup (props, { emit }) {
    const formatenTaxonomyStore = useFormatenComponentStore()
    const state: { formatenTaxonomies: RequestForFormatenHydraItem[] } = reactive({
      formatenTaxonomies: []
    })
    const selectedFormaten:Ref<FormatCode[]> = computed({
      get: () => {
        return [...new Set(props.value.map(fcode => state.formatenTaxonomies.find(f => f.formaat?.id === fcode.id)?.formaat as FormatCode))] ?? []
      },
      set: (value) => {
        emit('input', value)
      }
    })
    const formatenOptions: Ref<FormatCode[]> = ref([])
    const userStore = useUserStore()
    const isAdmin = computed(() => userStore.isAdmin)

    function formatLabel (formatOption:FormatCode): string {
      return `${formatOption.handler}`
    }

    function onRemove (removedOption: FormatCode, id:any) {
      selectedFormaten.value.splice(selectedFormaten.value.findIndex(format => format.id === removedOption.id), 1)
    }

    onMounted(() => {
      userStore.gettingUserInfo()
      formatenTaxonomyStore.getFormaten().then((formaten) => {
        state.formatenTaxonomies = formaten as RequestForFormatenHydraItem[]
        formatenOptions.value = [...new Set(formaten.map((formaat: RequestForFormatenHydraItem) => formaat.formaat))] as FormatCode[]
      })
    })

    return {
      formatenOptions,
      selectedFormaten,
      formatLabel,
      onRemove,
      isAdmin
    }
  }
})
