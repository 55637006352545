import { DlDayJs } from '../dayjs/dayjs'

export const dateToString = (date: Date | undefined | null, format = 'DD/MM/YYYY') => {
  return date ? DlDayJs(date).format(format) : ''
}

export const dateTimeToString = (date: Date | undefined | null, format = 'DD/MM/YYYY HH:mm') => {
  return date ? DlDayJs(date).format(format) : ''
}

export const dateIsEndOfDay = (date: Date | undefined) => {
  if (date) return DlDayJs(date).set('hour', 23).set('minute', 59).set('second', 59).toDate()
  return undefined
}

export const elapsedTimeBetweenDatesInString = (startDate:Date | undefined | null, endDate:Date | undefined |null) => {
  const delta = Math.abs(DlDayJs(endDate).toDate().getTime() - DlDayJs(startDate).toDate().getTime())
  const seconds = Math.floor(delta / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  return `${padTo2Digits(hours)}u${padTo2Digits(minutes % 60)}m${padTo2Digits(seconds % 60)}s`
}

function padTo2Digits (num: number) {
  return num.toString().padStart(2, '0')
}
