import { EnumAlertIcons, EnumAlertMethod, EnumAlertType } from '../store/alert'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'

export const HandleError = (error: unknown) => {
  return !axios.isCancel(error)
}

export const DefaultMessage = 'Er is een onbekende fout opgetreden'
export const DefaultTitle = 'Onbekende fout'

export const ErrorToString = (error: unknown) => {
  if (typeof error === 'string') {
    return error
  } else if (typeof error === 'object') {
    return ErrorToAlert(error).title
  }
}

export const ErrorToAlert = (error: unknown, method = EnumAlertMethod.Toast, closable = true) => {
  const { title, message } = updatingTitleAndMessage(error, DefaultTitle, DefaultMessage)
  return { title, message, icon: EnumAlertIcons.AlertCircle, type: EnumAlertType.Error, method, id: uuidv4(), closable }
}

const updatingTitleAndMessage = (error: unknown, title: string, message: string) => {
  if (typeof error === 'object' && error !== null) {
    if (HasTitleAndErrors(error)) {
      title = error.title
      message = error.errors.map(subError => `<p><strong>${subError.detail}:</strong> ${subError.title}</p>`).join('')
    } else if (HasTitleAndDetail(error) && error.detail) {
      title = error.title
      message = error.detail
    } else if (IsApiException(error) && error.isApiException) {
      title = `Status: ${error.status.toString()}`
      message = error.message
    } else if (HasMessageAndTitle(error)) {
      message = error.message || message
      title = error.title || title
    } else if (IsTypeError(error)) {
      message = error.message
      title = error.name
    } else if (HasOriginalErrorAndMessage(error)) {
      const { message: origMessage, title: origTitle } = updatingTitleAndMessage(error.originalErr, message, title)
      message = origMessage
      title = origTitle
    } else if (HasMessage(error)) {
      message = error.message
    }
  }
  return { title, message }
}

export const IsApiException = (error: object): error is {message: string, status: number, isApiException:boolean} => {
  return 'isApiException' in error
}

export const HasTitleAndDetail = (error: object):error is {title: string, detail?: string} => {
  return ['title', 'detail'].every(key => Object.keys(error).includes(key))
}

export const HasTitleAndErrors = (error: object):error is {title: string, errors: Array<{detail: string, title: string}>} => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return ['title', 'errors'].every(key => Object.keys(error).includes(key)) && Object.keys((error as any).errors).length > 0
}

export const HasMessageAndTitle = (error: object):error is {message: string, title: string} => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return ['message', 'title'].every(key => Object.getOwnPropertyNames(error).includes(key))
}

export const IsTypeError = (error: object):error is TypeError => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return error instanceof TypeError
}

export const HasOriginalErrorAndMessage = (error: object):error is {originalErr: Error} => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return Object.getOwnPropertyNames(error).includes('originalErr')
}

export const HasMessage = (error: object):error is {message: string} => {
  return Object.getOwnPropertyNames(error).includes('message')
}
