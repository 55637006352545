const DatasetModuleCreateEdit = () => import(/* webpackChunkName: "dataset" */ '@/modules/product-module/modules/dataset-group_create_edit-module/dataset-group_create_edit-module.vue')

export const RouteDatasetGroupCreate = {
  path: '/datasetgroup',
  name: 'dataset-group-create',
  component: DatasetModuleCreateEdit,
  meta: {
    breadcrumb: {
      label: 'datasetgroep'
    },
    authorize: false
  }
}

export const RouteDatasetGroupEdit = {
  path: '/datasetgroup/:id',
  name: 'dataset-group-edit',
  component: DatasetModuleCreateEdit,
  meta: {
    breadcrumb: {
      label: 'datasetgroep'
    },
    authorize: false
  }
}

export const DatasetGroupRoutes = [RouteDatasetGroupCreate, RouteDatasetGroupEdit]

export default DatasetGroupRoutes
