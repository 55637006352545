import { computed, ComputedRef, defineComponent, PropType } from '@vue/composition-api'

export interface IDlEntity{
    name: string;
    entiteitId: string;
    featureTypeId?: string | undefined;
    nietApartBestelbaar: boolean;
    nietBestelbaar: boolean;
    link:string;
}

export default defineComponent({
  name: 'dl-entities',
  props: {
    isAdmin: Boolean,
    value: {
      type: Array as PropType<IDlEntity[]>,
      required: true
    }
  },
  setup (props, { emit }) {
    const entities: ComputedRef<IDlEntity[]> = computed({
      get: () => props.value,
      set: (value) => {
        emit('input', props.value)
      }
    })

    const nietApartBestelbaarSelectLabel = computed(() => entities.value.every(x => x.nietApartBestelbaar) ? 'Deselecteer alles' : 'Selecteer alles')
    const nietBestelbaarSelectLabel = computed(() => entities.value.every(x => x.nietBestelbaar) ? 'Deselecteer alles' : 'Selecteer alles')

    function selectAllNietBestelbaar () {
      if (entities.value.every(x => x.nietBestelbaar)) {
        entities.value.forEach(entity => {
          entity.nietBestelbaar = false
        })
      } else {
        entities.value.forEach(entity => {
          entity.nietBestelbaar = true
        })
      }
    }

    function selectAllNietApartBestelbaar () {
      if (entities.value.every(x => x.nietApartBestelbaar)) {
        entities.value.forEach(entity => {
          entity.nietApartBestelbaar = false
        })
      } else {
        entities.value.forEach(entity => {
          entity.nietApartBestelbaar = true
        })
      }
    }

    return { entities, selectAllNietBestelbaar, selectAllNietApartBestelbaar, nietApartBestelbaarSelectLabel, nietBestelbaarSelectLabel }
  }
})
