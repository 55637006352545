import { RequestForRollenHydraItem } from '@/infrastructure/download-client/download-client'
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  ref,
  watch
} from '@vue/composition-api'
import { useRollenComponentStore } from './dl-rollen-state'
import { useUserStore } from '@/infrastructure/store/user'

export interface IDlRollen {
  isPublic: boolean
  rolIds: number[]
}

export default defineComponent({
  name: 'dl-rollen',
  props: {
    value: { type: Object as PropType<IDlRollen>, required: true }
  },
  setup (props, { emit }) {
    const rollenTaxonomyStore = useRollenComponentStore()
    const state: { rollenTaxonomies: RequestForRollenHydraItem[] } = reactive({
      rollenTaxonomies: []
    })
    const userStore = useUserStore()
    const isAdmin = computed(() => userStore.isAdmin)

    const needsRoles = ref(false)

    watch(needsRoles, () => {
      if (!needsRoles.value) rollenCompObject.value.rolIds = []
    })

    const rollenCompObject = computed({
      get: () => {
        return props.value
      },
      set: (value) => {
        emit('input', value)
      }
    })

    onMounted(() => {
      userStore.gettingUserInfo()
      rollenTaxonomyStore.getTaxonomies().then((taxonomies) => {
        state.rollenTaxonomies = taxonomies
      })
      needsRoles.value = rollenCompObject.value.rolIds.length > 0
    })

    return {
      state,
      rollenCompObject,
      needsRoles,
      isAdmin
    }
  }
})
