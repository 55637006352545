import {
  EnumStatusProduct
} from '@/infrastructure/download-client/download-client'
import { useUserStore } from '@/infrastructure/store/user'
import {
  computed,
  defineComponent,
  onMounted,
  PropType
} from '@vue/composition-api'
export interface IDlStatus {
  status: EnumStatusProduct;
  cantOrder: boolean;
  cantOrderSeperatly:boolean | undefined;
}

export const statuses = [
  {
    label: 'Ontwerp',
    value: EnumStatusProduct.Ontwerp
  },
  {
    label: 'Gepubliceerd',
    value: EnumStatusProduct.Gepubliceerd
  },
  {
    label: 'Gearchiveerd',
    value: EnumStatusProduct.Gearchiveerd
  }
].map((status) => ({
  ...status,
  id: status.label.toLowerCase().replace(/\s+/g, '')
}))

export default defineComponent({
  name: 'dl-status',
  emits: ['filter-values', 'filter-values-name'],
  props: {
    value: {
      type: Object as PropType<IDlStatus>,
      required: true
    },
    seperatly: { type: Boolean, default: false },
    showOrder: { type: Boolean, default: true },
    readonly: { type: Boolean, default: false }
  },
  setup (props, { emit }) {
    const showOrderBool = computed(() => props.showOrder)
    const selectStatus = computed({
      get: () => props.value.status === EnumStatusProduct.Nieuw ? EnumStatusProduct.Ontwerp : props.value.status,
      set: (statusSelected: EnumStatusProduct | null) => {
        emit('input', { ...props.value, status: statusSelected })
      }
    })

    const cantOrder = computed({
      get: () => props.value.cantOrder,
      set: (cantOrder: boolean) => {
        emit('input', { ...props.value, cantOrder })
      }
    })

    const cantOrderSeperatly = computed({
      get: () => props.value.cantOrderSeperatly as boolean,
      set: (cantOrderSeperatly: boolean) => {
        emit('input', { ...props.value, cantOrderSeperatly })
      }
    })

    const seperatlyShown = computed(() => {
      return props.seperatly
    })

    const userStore = useUserStore()
    const isAdmin = computed(() => userStore.isAdmin)
    onMounted(() => {
      userStore.gettingUserInfo()
    })

    return { showOrderBool, statuses, selectStatus, cantOrder, seperatlyShown, cantOrderSeperatly, isAdmin }
  }
})
