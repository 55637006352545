import { RouteConfig } from 'vue-router'
import { RouteProduct } from '../product-module/routes'

export const RouteBase: RouteConfig = {
  path: '/',
  // redirect: { name: RouteProduct.name },
  meta: {
    authorize: []
  }
}

const BaseRoutes = [RouteBase]

export default BaseRoutes
