import _Vue from 'vue'
import { IpproDatatable } from '@ippro/be.vlaanderen.ippro.web.vue/src/vl-ippro-datatable'
import { IpproMap } from '@ippro/be.vlaanderen.ippro.web.vue/src/vl-ippro-map'
import { IpproMapZoomToFeature } from '@ippro/be.vlaanderen.ippro.web.vue/src/vl-ippro-map/components/ippro-map-zoom-to-feature'

export default {
  install (Vue: typeof _Vue): void {
    Vue.component('ippro-datatable', IpproDatatable)
    Vue.component('ippro-map', IpproMap)
    Vue.component('ippro-map-zoom-to-feature', IpproMapZoomToFeature)
  }
}
