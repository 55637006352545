import { RouterInstance } from '@/infrastructure/router'
import { RouteDocument } from '@/modules/document-module/routes'
import { RouteDownloads } from '@/modules/downloads-module/routes'
import { RouteProduct } from '@/modules/product-module/routes'
import { RouteBase } from '@/modules/routes'
import {
  computed,
  defineComponent
} from '@vue/composition-api'

export default defineComponent({
  name: 'dl-navigation-header',
  setup () {
    const navigationTabs = computed(() => [
      {
        name: 'DOWNLOAD ADMIN',
        link: RouteBase.name ?? '/',
        hasDivider: false,
        active: false
      },
      {
        name: 'Bestellingen',
        link: `/${RouteDownloads.name}`,
        hasDivider: true,
        active: RouterInstance.data.value.name === RouteDownloads.name
      },
      {
        name: 'Producten',
        link: `/${RouteProduct.name}`,
        hasDivider: true,
        active: RouterInstance.data.value.name === RouteProduct.name
      },
      {
        name: 'Documenten',
        link: `/${RouteDocument.name}`,
        hasDivider: false,
        active: RouterInstance.data.value.name === RouteDocument.name
      }
    ]
    )

    return { navigationTabs }
  }
})
