import DocumentRoutes from '@/modules/document-module/routes'
import DownloadDetailRoutes from '@/modules/downloads-module/modules/download-detail-module/routes'
import DownloadRoutes from '@/modules/downloads-module/routes'
import HomeRoutes from '@/modules/home-module/routes'
import DatasetGroupRoutes from '@/modules/product-module/modules/dataset-group_create_edit-module/routes'
import DatasetRoutes from '@/modules/product-module/modules/dataset-module/routes'
import EntityEditRoutes from '@/modules/product-module/modules/entity-module/routes'
import ProductRoutes from '@/modules/product-module/routes'
import BaseRoutes from '@/modules/routes'
import UserRoutes from '@/modules/user-module/routes'
import ErrorRoutes from '@/modules/error-module/routes'
import vueCompositionApi, {
  computed,
  ComputedRef,
  reactive
} from '@vue/composition-api'
import Vue from 'vue'
import VueRouter, { NavigationGuard, Route, RouteConfig } from 'vue-router'
import { AuthHandler } from './guards/auth-guard'

export interface IRouteData {
  name: Route['name'];
  params: Route['params'];
  query: Route['query'];
}

Vue.use(vueCompositionApi)

const routeData: IRouteData = reactive({ params: {}, query: {}, name: null })
export const routes: RouteConfig[] = [
  ...HomeRoutes,
  ...ProductRoutes,
  ...DocumentRoutes,
  ...EntityEditRoutes,
  ...DatasetGroupRoutes,
  ...DatasetRoutes,
  ...BaseRoutes,
  ...DownloadRoutes,
  ...DownloadDetailRoutes,
  ...UserRoutes,
  ...ErrorRoutes
]

export const initRouter = ({
  routes,
  guards
}: {
  routes?: RouteConfig[];
  guards?: NavigationGuard[];
}) => {
  Vue.use(VueRouter)
  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  })

  router.afterEach((route) => {
    routeData.params = route.params
    routeData.query = route.query
    routeData.name = route.name
  })

  guards?.forEach((guard) => {
    router.beforeEach(guard)
  })

  return router
}

export const defaultGuards = [AuthHandler]
export const RouterInstance: {
  router: VueRouter | null;
  data: ComputedRef<IRouteData>;
} = { router: null, data: computed(() => routeData) }
