import { RequestForVersnijdingenHydraItem } from '@/infrastructure/download-client/download-client'
import { computed, defineComponent, onMounted, PropType, reactive, Ref, ref, watch } from '@vue/composition-api'
import { useVersnijdingComponentStore } from './dl-versnijding-state'
import dlVersnijdingParent from './components/dl-versnijding-parent.vue'
import { useUserStore } from '@/infrastructure/store/user'

export interface VersnijdingObject{
  versnijdingen:RequestForVersnijdingenHydraItem[]
  allowClip:boolean
  allowCut:boolean
}

export type TVersnijdingItem = Omit<RequestForVersnijdingenHydraItem, 'versnijdingEnum'>

export interface VersnijdingWithChildren extends TVersnijdingItem{
  children: VersnijdingWithChildren[]
}

const kaartbladParent: TVersnijdingItem = {
  id: 11,
  code: 'Kaartblad NGI',
  naam: 'Kaartblad',
  parent: undefined
}

export const frontOnlyParents = [kaartbladParent]

export default defineComponent({
  name: 'dl-versnijding',
  props: {
    value: { type: Object as PropType<VersnijdingObject>, required: true },
    requiredHistoric: { type: Boolean, default: false, required: false },
    requiredDifference: { type: Boolean, default: false, required: false }
  },
  emits: ['input'],
  components: {
    dlVersnijdingParent
  },
  setup (props, { emit }) {
    const versnijdingTaxonomyStore = useVersnijdingComponentStore()
    const state: {versnijdingTaxonomies: VersnijdingWithChildren[]} = reactive({ versnijdingTaxonomies: [] })
    const checkedList: Ref<number[]> = ref([])
    const userStore = useUserStore()
    const isAdmin = computed(() => userStore.isAdmin)

    const childUpdated = ({ id, checked }: {id:number, checked:boolean}) => {
      const idx = checkedList.value.indexOf(id)
      if (checked && idx === -1) {
        checkedList.value.push(id)
      } else if (!checked && idx > -1) {
        checkedList.value.splice(idx, 1)
      }
      emit('input', { versnijdingen: checkedList.value, allowCut: props.value.allowCut, allowClip: props.value.allowClip })
    }
    const parsedRule = computed(() => checkedList.value.length === 0 ? 'required' : '')
    const parsedCutRule = computed(() => props.requiredDifference || props.requiredHistoric ? 'required' : '')

    onMounted(() => {
      userStore.gettingUserInfo()
      versnijdingTaxonomyStore.getTaxonomies().then(taxonomies => {
        state.versnijdingTaxonomies = childMaker(frontifyVersnijdingTaxonomy(taxonomies))
      })
      checkedList.value = props.value.versnijdingen.map(x => x.id)
    })

    return {
      checkedList,
      childUpdated,
      state,
      parsedRule,
      parsedCutRule,
      isAdmin
    }
  }
})

const childMaker = (list: TVersnijdingItem[]) => {
  const listWithChildren: VersnijdingWithChildren[] = list.map(listItem => ({ ...listItem, children: [] }))
  const map: {[key: string]: number} = {}
  let node
  const roots = []
  let i

  for (i = 0; i < listWithChildren.length; i += 1) {
    map[listWithChildren[i].id] = i
    listWithChildren[i].children = []
  }

  for (i = 0; i < listWithChildren.length; i += 1) {
    node = listWithChildren[i]
    if (node.parent) {
      listWithChildren[map[node.parent]].children.push(node)
    } else {
      roots.push(node)
    }
  }
  return roots
}

const frontifyVersnijdingTaxonomy = (taxonomies: TVersnijdingItem[]) => {
  taxonomies = [...taxonomies, ...frontOnlyParents]
  taxonomies = taxonomies.map(taxonomy => {
    switch (taxonomy.id) {
      case 10:
      case 9:
      case 8:
        taxonomy.parent = kaartbladParent.id
    }
    return taxonomy
  })
  return taxonomies
}
