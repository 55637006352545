const HomeModule = () => import('@/modules/home-module/home-module.vue')

export const RouteHome = {
  path: '/',
  name: 'home',
  component: HomeModule,
  meta: {
    breadcrumb: {
      label: 'Home'
    },
    authorize: []
  }
}

const HomeRoutes = [RouteHome]

export default HomeRoutes
