import _Vue from 'vue'

import DlContainer from '@/components/dl-container/dl-container.vue'
import DlFormField from '@/components/dl-form-field/dl-form-field.vue'
import DlPeriod from '@/components/dl-period/dl-period.vue'
import dlAlertPage from '@/components/dl-alert-page/dl-alert-page.vue'
import dlVersnijding from '@/components/dl-versnijding/dl-versnijding.vue'
import dlRollen from '@/components/dl-rollen/dl-rollen.vue'
import dlStatus from '@/components/dl-status/dl-status.vue'
import dlFormaten from '@/components/dl-formaten/dl-formaten.vue'
import DlVersionHeader from '@/components/dl-version-header/dl-version-header.vue'
import dlPredefinedDocuments from '@/components/dl-predefined-documents/dl-predefined-documents.vue'
import dlDocumenten from '@/components/dl-documenten/dl-documenten.vue'
import dlHistoricVersions from '@/components/dl-historic-versions/dl-historic-versions.vue'
import dlNavigationHeader from '@/components/dl-navigation-header/dl-navigation-header.vue'
import dlEntities from '@/components/dl-entities/dl-entities.vue'
import dlRequeueButton from '@/components/dl-requeue-button/dl-requeue-button.vue'

export default {
  install (Vue: typeof _Vue): void {
    Vue.component('dl-container', DlContainer)
    Vue.component('dl-form-field', DlFormField)
    Vue.component('dl-period', DlPeriod)
    Vue.component('dl-alert-page', dlAlertPage)
    Vue.component('dl-versnijding', dlVersnijding)
    Vue.component('dl-rollen', dlRollen)
    Vue.component('dl-status', dlStatus)
    Vue.component('dl-predefined-documents', dlPredefinedDocuments)
    Vue.component('dl-version-header', DlVersionHeader)
    Vue.component('dl-documenten', dlDocumenten)
    Vue.component('dl-formaten', dlFormaten)
    Vue.component('dl-historic-versions', dlHistoricVersions)
    Vue.component('dl-navigation-header', dlNavigationHeader)
    Vue.component('dl-entities', dlEntities)
    Vue.component('dl-requeue-button', dlRequeueButton)
  }
}
