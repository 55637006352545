import { DownloadClientInstance } from '@/infrastructure/download-client/dl-client'
import { RequestForDocumentHydraItem } from '@/infrastructure/download-client/download-client'
import { defineStore } from 'pinia'

export const useDocumentenComponentStore = defineStore('documenten-component', {
  state: ():{documentenList:RequestForDocumentHydraItem[]|null} => ({
    documentenList: null
  }),
  actions: {
    async getDocumenten () {
      return (this.documentenList || DownloadClientInstance.documents_GetDocuments(null, undefined, null, null, null, null, 0, 2147483647, undefined).then((documenten) => {
        this.documentenList = documenten.member as RequestForDocumentHydraItem[]
        return documenten.member
      }))
    }
  }
})
