import { DownloadClientInstance } from '@/infrastructure/download-client/dl-client'
import { RequestForVersnijdingenHydraItem } from '@/infrastructure/download-client/download-client'
import { defineStore } from 'pinia'

export const useVersnijdingComponentStore = defineStore('versnijding-component', {
  state: (): {versnijdingTaxonomies: RequestForVersnijdingenHydraItem[] | null} => ({
    versnijdingTaxonomies: null
  }),
  actions: {
    async getTaxonomies () {
      return this.versnijdingTaxonomies || DownloadClientInstance.taxonomy_Versnijdingen().then(taxonomy => { this.versnijdingTaxonomies = taxonomy; return taxonomy })
    }
  }
})
