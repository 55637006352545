import { RequestForDocumentHydraItem } from '@/infrastructure/download-client/download-client'
import { sizeToString } from '@/infrastructure/helpers/size-manipulation'
import { mimeTypes } from '@/infrastructure/mime-type'
import { computed, defineComponent, onMounted, PropType, reactive, ref, Ref, watch } from '@vue/composition-api'
import { useDocumentenComponentStore } from './dl-documenten-state'
import { useUserStore } from '@/infrastructure/store/user'

export default defineComponent({
  name: 'dl-documenten',
  props: {
    value: {
      type: Array as PropType<RequestForDocumentHydraItem[]>,
      required: true
    }
  },
  setup (props, { emit }) {
    const documentenStore = useDocumentenComponentStore()
    const state: {documentenList: RequestForDocumentHydraItem[]} = reactive({
      documentenList: []
    })
    const selectedDocuments:Ref<RequestForDocumentHydraItem[]> = computed({
      get: () => {
        return [...new Set(props.value.map(doc => state.documentenList.find(d => d.id === doc.id) as RequestForDocumentHydraItem))] ?? []
      },
      set: (value) => {
        emit('input', value)
      }
    })
    const userStore = useUserStore()
    const isAdmin = computed(() => userStore.isAdmin)

    const documentenOptions:Ref<RequestForDocumentHydraItem[]> = ref([])

    function documentLabel (document:RequestForDocumentHydraItem): string {
      const extension = mimeTypes.getExtension(document.type as string) as string
      return `${document.naam} (${extension !== null ? extension.toUpperCase() : ''} - ${sizeToString(document.size)}) - ${document.nummer}`
    }

    function onRemove (removedOption: any, id: any) {
      selectedDocuments.value.splice(selectedDocuments.value.findIndex(selection => selection.id === removedOption.id), 1)
    }

    onMounted(() => {
      userStore.gettingUserInfo()
      documentenStore.getDocumenten().then((documenten) => {
        state.documentenList = documenten as RequestForDocumentHydraItem[]
        documentenOptions.value = (documenten as RequestForDocumentHydraItem[]).sort((a, b) => (a.naam as string).localeCompare((b.naam as string))) ?? []
      })
    })

    return {
      selectedDocuments,
      onRemove,
      documentenOptions,
      documentLabel,
      isAdmin
    }
  }
})
