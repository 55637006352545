import Vue from 'vue'
import * as WebComponents from '@govflanders/vl-ui-vue-components'
import dayjs from 'dayjs'
import { dateToString } from '../helpers/date-manipulation'
import { FormatCode } from '../download-client/download-client'

export default {
  install (vue: typeof Vue): void {
    vue.use(WebComponents)
    vue.directive('vl-tooltip', WebComponents.VlTooltip)
    vue.directive('vl-modal-toggle', WebComponents.VlModalToggle)

    WebComponents.extend('test', {
      validate: (value: string) => !!/^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/gi.exec(
        value
      ),
      message: '{_field_} voldoet niet aan het patroon: DD.MM.YYYY'
    })

    WebComponents.extend('before', {
      validate: (value: Date, { before }: { before: Date }) => before ? dayjs(value).isBefore(before) : true,
      params: ['before'],
      message: (fieldName: string, params: { before: Date }) => `'${fieldName}' komt na ${dateToString(params.before)}`
    })

    WebComponents.extend('after', {
      validate: (value: Date, { after }: { after: Date }) => after ? dayjs(value).isAfter(after) : true,
      params: ['after'],
      message: (fieldName: string, params: { after: Date }) => `'${fieldName}' komt voor ${dateToString(params.after)}`
    })

    WebComponents.extend('oneCut', (value:unknown, length:any) => {
      return { value: true, data: { required: true } }
    }, { computesRequired: true })

    WebComponents.extend('noCut', {
      validate: (value: string[]) => !value.length,
      message: 'Er is een formaat toegewezen maar versnijdingen zijn niet toegelaten.'
    })

    WebComponents.extend('minTwo', {
      validate: (value: string[]) => value.length > 1,
      message: 'Er is een formaat toegewezen maar versnijdingen zijn niet toegelaten.'
    })

    WebComponents.extend('distinctFormat', {
      validate: (value:FormatCode[]) => {
        return value.length >= 2 ? new Set(value.map(x => x.formaat)).size === value.length : true
      },
      message: 'Geselecteerde formaten hebben dezelfde waarde. Gelieve unieke waarden te selecteren'
    })
  }
}
