import 'reflect-metadata'
import { EnumAlertType, useAlertStore } from '@/infrastructure/store/alert'
import { defineComponent, onMounted } from '@vue/composition-api'
import * as widgetClient from '@govflanders/vl-widget-client'
import { GenerateErrorToaster } from '@/infrastructure/alert-handler/alert-toaster'
import { DefaultLoginUrl, DefaultLogoutUrl } from '@/infrastructure/adapters/environment-variables'
import { useUserStore } from '@/infrastructure/store/user'

export default defineComponent({
  name: 'base-module',
  setup: () => {
    const alertStore = useAlertStore()
    const removeAlert = (id:string) => {
      alertStore.removeAlert(id)
    }

    const widgetCapture = () => {
      widgetClient.capture((widget) => {
        widget.getExtension('citizen_profile.session').then((session) => {
          return (session as any).configure({
            active: !!useUserStore().getUserInfo,
            endpoints: {
              loginUrl: DefaultLoginUrl,
              logoutUrl: DefaultLogoutUrl
            }
          })
        })
      }, (widget) => widget.getPluginTypeId() === 'global_header')
    }

    const initGlobalHeader = async () => {
      try {
        const header = await widgetClient.bootstrap(process.env.VUE_APP_GLOBAL_HEADER as string)
        header.setMountElement(document.getElementById('global-header-placeholder'))
        header.mount()
      } catch (error) {
        console.error(error)
      }
    }

    const initGlobalFooter = async () => {
      try {
        const footer = await widgetClient.bootstrap(process.env.VUE_APP_GLOBAL_FOOTER as string)
        footer.setMountElement(document.getElementById('global-footer-placeholder'))
        footer.mount()
      } catch (error) {
        console.error(error)
      }
    }

    const alertTypeAttribute = (alertType: EnumAlertType | undefined) => {
      switch (alertType) {
        case EnumAlertType.Error:
          return 'mod-error'
        case EnumAlertType.Success:
          return 'mod-success'
        case EnumAlertType.Warning:
          return 'mod-warning'
        default:
          return 'mod-info'
      }
    }

    onMounted(() => {
      widgetCapture()
      initGlobalFooter()

      useUserStore().gettingUserInfo().then(() => {
        initGlobalHeader()
      })
    })

    return {
      alertStore,
      alertTypeAttribute,
      removeAlert
    }
  }
})
