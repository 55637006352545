import Mime from 'mime/Mime'

// eslint-disable-next-line @typescript-eslint/no-var-requires
export const mimeTypes = new Mime(require('mime/types/standard.js'))
// eslint-disable-next-line @typescript-eslint/no-var-requires
mimeTypes.define(require('mime/types/other.js'))
mimeTypes.define({
  'image/pjpeg': ['pjpeg'],
  'application/x-zip-compressed': ['zip'],
  'application/zip': ['zip']
}, true)
