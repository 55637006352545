import { RouterInstance } from '@/infrastructure/router'
import { EnumAlertMethod, EnumAlertType, IAlert } from '@/infrastructure/store/alert'
import { computed, defineComponent, PropType, ref, Ref } from '@vue/composition-api'

export default defineComponent({
  name: 'dl-alert-page',
  props: {
    fetching: { type: Boolean, default: true },
    replacePageAlert: { type: Object as PropType<IAlert> },
    alerts: { type: Array as PropType<IAlert[]>, default: () => [] }
  },
  setup (props) {
    const reload = () => {
      RouterInstance.router?.go(0)
    }
    const toastAlerts = computed(() => props.alerts.filter(alert => alert.method === EnumAlertMethod.Toast).map(alert => ({ ...alert, closable: true })))
    const hiddenAlerts: Ref<string[]> = ref([])

    const alertTypeAttribute = (alertType: EnumAlertType | undefined) => {
      switch (alertType) {
        case EnumAlertType.Error:
          return 'mod-error'
        case EnumAlertType.Success:
          return 'mod-success'
        case EnumAlertType.Warning:
          return 'mod-warning'
        default:
          return 'mod-info'
      }
    }
    const canShow = (id: string) => !hiddenAlerts.value.includes(id)

    const removeAlert = (id: string) => {
      if (!hiddenAlerts.value.includes(id)) hiddenAlerts.value.push(id)
    }

    return {
      alertTypeAttribute,
      canShow,
      reload,
      removeAlert,
      toastAlerts
    }
  }
})
