import { flatten } from '@/infrastructure/helpers/array-manipulation'
import { computed, defineComponent, onMounted, PropType, toRefs } from '@vue/composition-api'
import { frontOnlyParents, VersnijdingWithChildren } from '../dl-versnijding'
import { useUserStore } from '@/infrastructure/store/user'

export default defineComponent({
  name: 'dl-versnijding-parent',
  props: {
    node: { type: Object as PropType<VersnijdingWithChildren>, required: true },
    checkedList: { type: Array as PropType<number[]>, required: true },
    classes: { type: Object as PropType<string> }
  },
  setup (props, { emit }) {
    const { node, checkedList } = toRefs(props)
    const isChecked = computed({
      get: () => {
        return checkedList.value.includes(node.value.id)
      },
      set: (value: boolean) => {
        emit('child-update', { id: node.value?.id, checked: value })
        if (!value) {
          flatten(node.value.children).forEach((node) => {
            emit('child-update', { id: node, checked: false })
          })
        }
      }
    })
    const userStore = useUserStore()
    const isAdmin = computed(() => userStore.isAdmin)

    const childUpdated = ({
      id,
      checked
    }: {
      id: string;
      checked: boolean;
    }) => {
      if (checked && !isPlaceholder.value) {
        emit('child-update', { id: node.value.id, checked: true })
      }
      emit('child-update', { id, checked })
    }

    const isPlaceholder = computed(
      () =>
        frontOnlyParents.findIndex(
          (frontOnlyParent) =>
            frontOnlyParent.id === node.value.id
        ) > -1
    )
    onMounted(() => {
      userStore.gettingUserInfo()
    })

    return { isChecked, childUpdated, isPlaceholder, isAdmin }
  }
})
