export const flatten = (inputArray: any[]): string[] => {
  let children: any[] = []

  return inputArray.map(input => {
    if (input.children && input.children.length) {
      children = [...children, ...input.children]
    }
    return input.versnijdingId
  }).concat(children.length ? flatten(children) : children)
}

export interface IFlatArrayElement {
    indentation: number
    parentIsDisabled?: boolean
    [index: string]: any
  }

export const Flattener = (
  flatArray: IFlatArrayElement[],
  initial: any[],
  indentation = 0,
  disableParent = false,
  accessor = 'Children'
) => {
  try {
    if (initial && Array.isArray(initial)) {
      initial.forEach(({ [accessor]: acc, ...type }) => {
        flatArray.push({
          indentation,
          parentIsDisabled: !!acc && acc.length > 0 && disableParent,
          ...type
        })
        if (acc && acc.length > 0) {
          Flattener(flatArray, acc, indentation + 1, disableParent, accessor)
        }
      })
    }
  } catch (error) {
    /* console.error(error); */
  }
}
