const DocumentComponent = () =>
  import('@/modules/document-module/document-module.vue')

export const RouteDocument = {
  path: '/documenten',
  name: 'documenten',
  component: DocumentComponent,
  meta: {
    breadcrumb: {
      label: 'Documenten'
    },
    authorize: []
  }
}

const DocumentRoutes = [RouteDocument]

export default DocumentRoutes
