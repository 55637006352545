import { defineStore } from 'pinia'
import { DownloadClientInstance } from '../download-client/dl-client'
import { UserResponse } from '../download-client/download-client'

export const downloadAdminRole = 'DVDownloadToepassingGebruiker-admin'

export const useUserStore = defineStore('user', {
  state: (): {userInfo: UserResponse | null} => ({
    userInfo: null
  }),
  getters: {
    getUserInfo (state) {
      return state.userInfo
    },
    isAdmin (state) {
      return state.userInfo && state.userInfo.roles && state.userInfo.roles.includes(downloadAdminRole)
    }
  },
  actions: {
    async gettingUserInfo () {
      if (this.userInfo?.userId) {
        return true
      }
      try {
        this.userInfo = await DownloadClientInstance.auth_UserInfo()
        return true
      } catch (error: any) {
        this.userInfo = null
        return false
      }
    }
  }
})
