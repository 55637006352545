import { DefaultLoginUrl } from '@/infrastructure/adapters/environment-variables'
import { useUserStore } from '@/infrastructure/store/user'
import { NavigationGuard } from 'vue-router'
import { IRoute } from '../interfaces'
import { RouteError } from '@/modules/error-module/routes'

export const AuthHandler: NavigationGuard = async (
  to: IRoute,
  from: IRoute,
  next
) => {
  const loggedOn = await useUserStore().gettingUserInfo()
  const isErrorPage = to?.name === RouteError.name

  if (!loggedOn && !isErrorPage) {
    location.replace(DefaultLoginUrl)
    return
  }
  next()
}
