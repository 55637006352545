import Vue from 'vue'
import App from '@/modules/app.vue'
import { defaultGuards, initRouter, RouterInstance, routes } from './infrastructure/router'
import { createPinia } from 'pinia'
import { loadVueLibs } from './infrastructure/setup/vue-libs'

loadVueLibs(Vue)

RouterInstance.router = initRouter({ guards: defaultGuards, routes })

new Vue({
  router: RouterInstance.router,
  render: h => h(App),
  pinia: createPinia()
}).$mount('#app')
