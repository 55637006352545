import { DownloadClientInstance } from '@/infrastructure/download-client/dl-client'
import { RequestForRollenHydraItem } from '@/infrastructure/download-client/download-client'
import { defineStore } from 'pinia'

export const useRollenComponentStore = defineStore('rollen-component', {
  state: (): { rollenTaxonomies: RequestForRollenHydraItem[] | null } => ({
    rollenTaxonomies: null
  }),
  actions: {
    async getTaxonomies () {
      return (
        this.rollenTaxonomies ||
        DownloadClientInstance.taxonomy_Rollen().then((taxonomy) => {
          this.rollenTaxonomies = taxonomy
          return taxonomy
        })
      )
    }
  }
})
